import React from "react";
import { connect } from "react-redux";
import { Creators } from "../../store/ducks/register";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

class Register extends React.Component {
  state = {
    name: "",
    last_name: "",
    cpf: "",
    email: "",
    estado: "",
    cidade: "",
    password: "",
    error: ""
  };

  handleSignUp = async e => {
    e.preventDefault();
    const {
      name,
      last_name,
      cpf,
      email,
      password,
      state,
      city
    } = this.state;
    
    if (
      !name ||
      !email ||
      !password ||
      !last_name ||
      !cpf ||
      !state ||
      !city
    ) {
      this.setState({ error: "Preencha todos os dados para se cadastrar" });
    } else {
      try {
        await this.props.dispatch(
          Creators.register(
            name,
            last_name,
            cpf,
            email,
            password,
            state,
            city
          )
        );
      } catch (err) {
        // console.log(err);
        this.setState({ error: "Ocorreu um erro ao registrar sua conta." });
      }
    }
  };

  render() {
    return (
      <>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              {/* <div className="text-muted text-center mt-2 mb-4">
                <small>Inscreva-se com</small>
              </div>
              <div className="text-center">
                <Button
                  className="btn-neutral btn-icon mr-4"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/github.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Github</span>
                </Button>
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div> */}
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Ou inscreva-se com credenciais</small>
              </div>
              <div className="text-center">
                {this.state.error && <p>{this.state.error}</p>}
              </div>
              <Form role="form" onSubmit={this.handleSignUp}>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Nome"
                      type="text"
                      // onChange={e => this.onChange("name", e.target.value)}
                      onChange={e => this.setState({ name: e.target.value })}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Sobrenome"
                      type="text"
                      // onChange={e => this.onChange("last_name", e.target.value)}
                      onChange={e =>
                        this.setState({ last_name: e.target.value })
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      // onChange={e => this.onChange("email", e.target.value)}
                      onChange={e => this.setState({ email: e.target.value })}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="CPF"
                      type="number"
                      // onChange={e => this.onChange("cpf", e.target.value)}
                      onChange={e => this.setState({ cpf: e.target.value })}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Senha"
                      type="password"
                      // onChange={e => this.onChange("password", e.target.value)}
                      onChange={e =>
                        this.setState({ password: e.target.value })
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-muted font-italic">
                  <small>
                    Força da senha:{" "}
                    <span className="text-success font-weight-700">Forte</span>
                  </small>
                </div>
                <Row className="my-4">
                  <Col xs="12">
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="customCheckRegister"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheckRegister"
                      >
                        <span className="text-muted">
                          Concordo com o{" "}
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            Política de Privacidade
                          </a>
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="submit"
                    onClick={() => this.handleSignUp}
                  >
                    Criar Conta
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

const mapStateToProps = state => ({
  register: state.register.data
});

const mapDispatchToProps = dispatch => {
  return {
    authorize: (email, password) =>
      dispatch(Creators.authorize(email, password)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
