import React from "react";
import ReactDOM from "react-dom";
import 'dotenv/config';
import { Route, Switch, Redirect } from "react-router-dom";
import { Router } from "react-router-dom";
import './ReactotronConfig'

import Auth from "./services/client/Auth";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import { getToken } from "./services/auth";
import PayDetail from "./views/pages/PayDetail";

import AdminLayout from "./layouts/Admin";
import AuthLayout from "./layouts/Auth";
import { Provider } from "react-redux";
import history from "./helpers/history";
// import store from "./store/store";
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      Auth.isUserAuthenticated === getToken() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/auth/login" />
      )
    }
  />
);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate
      loading={null}
      persistor={persistor}>
      <Router history={history}>
          <Switch>
            <PrivateRoute
              exact
              path="/admin"
              render={props => <AdminLayout {...props} />}
            />
            <Route
              path="/admin/home"
              render={props => <AdminLayout {...props} />}
            />
            <Route
              path="/admin/profile"
              render={props => <AdminLayout {...props} />}
            />
            <Route
              path="/admin/usuarios"
              render={props => <AdminLayout {...props} />}
            />
            <Route
              path="/admin/usuarios/:id"
              render={props => <AdminLayout {...props} />}
            />

            <Route
              path="/admin/home"
              render={props => <AdminLayout {...props} />}
            />
            <Route
              exact
              path="/admin/pagamentos"
              // path="/admin/pagamentos"
              render={props => <AdminLayout {...props} />}
            />
            <Route
              path="/admin/pagamentos/:id"
              render={props => <PayDetail {...props} />}
            />
            <Route
              path="/admin/funcionarios"
              render={props => <AdminLayout {...props} />}
            />
            <Route path="/auth/" render={props => <AuthLayout {...props} />} />
            <Redirect from="/" to="/admin/home" />
          </Switch>
        </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
