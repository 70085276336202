import React from "react";
import api from "../../services/api";
import logo from "../../assets/img/theme/loading.gif";
import ModalEditPayment from "./modal";
import { Link } from "react-router-dom";
import { cpfMask } from "../../helpers/maskCpf";
import Sidebar from "../components/Sidebar/Sidebar";
import routes from "../../routes";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";

import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  Button,
  Container,
  Badge,
  Row
} from "reactstrap";

class PayDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payments: "",
      userPayment: ""
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    api.get(`/admin/payments/${id}`).then(response => {
      this.setState({
        payments: response.data.payment
      });
    });
  }

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  Payment = payment => {
    return payment !== "" ? (
      <Card>
        <CardBody>
          <h1>{payment.name_bill || ""}</h1>
          <Badge color="dark">Valor:</Badge>
          <h1>{payment.amount}</h1>
          <Badge color="dark">Data de Vencimento:</Badge>
          <h1>{payment.due_data} </h1>
          <Badge color="dark">Nosso numero:</Badge>
          <h1>{payment.our_number}</h1>
          <Badge color="dark">Codigo Assinatura:</Badge>
          <h1>{payment.assignor_code}</h1>
          <Badge color="dark">Data do pagamento:</Badge>
          <h1>{payment.paid_date}</h1>
          <Badge color="dark">Nome do pagador:</Badge>
          <h1>
            {payment.full_name}
          </h1>
          <Badge color="dark">E-mail:</Badge>
          <h1>{payment.usuario.email}</h1>
          <Badge color="dark">Cidade:</Badge>
          <h1>{payment.usuario.city}</h1>
          <Badge color="dark">CPF:</Badge>
          <h1>{cpfMask(payment.usuario.cpf)}</h1>
          <Badge color="dark">Data do pagamento:</Badge>
          <h1>{payment.paid_date}</h1>
        </CardBody>
      </Card>
    ) : (
      <Card>
        <CardBody>
          <CardText>
            <span className="m-4">
              <img src={logo} width="50px" alt="loading..." />
              Carregando registros...
            </span>
          </CardText>
        </CardBody>
      </Card>
    );
  };

  render() {
    const { payments } = this.state;
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/admin/home",
            imgSrc: require("assets/img/brand/argon-react.png"),
            imgAlt: "..."
          }}
          style={{ zIndex: "99999" }}
        />
        <div className="bg-gradient-info">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
            
          />
        </div>

        {/* Page content */}
        <div className="container" style={{ paddingRight: 15, paddingLeft: 15, marginRight: 0,  display: 'block'}}> 
          <div className="main-content" ref="mainContent">
            <Container className="mt--10 col-md-10">
              {/* Table */}
              <Row>
                <div className="col mt-5">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <Row>
                        <div className="m-1">
                          <Button color="danger" >
                            <Link to={'/pagamentos'}>
                              Voltar
                            </Link>
                          </Button>
                        </div>
                        <div className="m-1">
                          <Button color="primary">Imprimir</Button>
                        </div>
                        <div className="m-1">
                          <Button color="primary">Enviar por e-mail</Button>
                        </div>
                      </Row>
                    </CardHeader>
                    <CardBody>{this.Payment(payments)}</CardBody>
                  </Card>
                  <ModalEditPayment
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                  ></ModalEditPayment>
                </div>
              </Row>
            </Container>
            <Container fluid>
              <AdminFooter />
            </Container>
          </div>
        </div>
      </>
    );
  }
}

// function mapStateToProps(state, ownProps) {
//   return {
//       goBack: ownProps.router.goBack
//   };
// }

// export default connect(mapStateToProps)(PayDetail);

export default PayDetail;
