import React from "react";
import api from "../../services/api";
import logo from "../../assets/img/theme/loading.gif";
import { cpfMask } from "../../helpers/maskCpf";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Header from "../components/Headers/Header";
import {
  Badge,
  Card,
  CardHeader,
  Button,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  PaginationItem,
  Pagination,
  PaginationLink,
} from "reactstrap";


class TableUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      button: "",
      error: null,
      users: [],
      query: '',
      paginate: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.RequestPayments();
  }



  fetchSearchResults = async (query) => {

    const {data} = await api.get(`admin/users?query=${query}`);
    
    if(!data){
      this.RequestPayments();
      console.log('não há pagamentos');
    } else {
      this.setState({
        users: data,
      })    
    }
  }

  RequestPayments = async event => {
    const page = typeof event !== "undefined" ? event.target.value : null;

    await api.get("admin/users", {
      params: {
        page
      }
    }).then(response => {
      this.setState({ 
        users: response.data.users.data,
        paginate: response.data.users,
        total: response.data.users.total

      });
    }).catch(function (error){
      console.log(error);
    });
  }

  handleOnInputChange = ( event ) => {
    const query = event.target.value;

    if (!query) {
      this.setState({ query, message:'' });
      this.RequestPayments();
		} else {
      this.setState({
        query: query,
        loading: true,
        message: ''
      }, () => {
        this.fetchSearchResults(query);
      });
		}
  }

  getStatusUser({ status }) {
    if (status === 0) {
      return (
        <Badge className="badge-dot mr-4 ml-3 text-dark">
          <i className="bg-warning" /> Inativo
        </Badge>
      );
    }

    if (status === 1) {
      return (
        <Badge className="badge-dot mr-4 ml-3 text-dark">
          <i className="bg-success" /> Ativo
        </Badge>
      );
    }
  }

  Paginate = () => {
    const { paginate } = this.state;

    if (paginate === null) return <> </>;

    const { last_page, current_page } = paginate;

    let pageItem = [];
    let nextPage = current_page === last_page ? 0 : current_page + 1;
    let previousPage = current_page !== 1 ? current_page - 1 : 0;

    for (let i = 0; i < paginate.last_page; i++) {
      let indication = i + 1;

      pageItem.push(
        <PaginationItem
          key={Math.random() * i}
          className={i === current_page - 1 ? "active" : "none"}
        >
          <PaginationLink
            onClick={event => this.RequestPayments(event)}
            value={indication}
          >
            {indication}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return (
      <Pagination className="justify-content-center d-flex mt-3">
        <div className="d-flex">
          <PaginationItem className={previousPage === 0 ? "disabled" : ""}>
            <PaginationLink
              onClick={event => this.RequestPayments(event)}
              tabIndex="-1"
              value={previousPage}
            >
              <i className="fa fa-angle-left" />
              <span className="sr-only">Anterior</span>
            </PaginationLink>
          </PaginationItem>

          {pageItem}

          <PaginationItem className={nextPage === 0 ? "disabled" : ""}>
            <PaginationLink
              onClick={event => this.RequestPayments(event)}
              value={nextPage}
            >
              <i className="fa fa-angle-right" />
              <span className="sr-only"> Proximo </span>
            </PaginationLink>
          </PaginationItem>
        </div>
      </Pagination>
    );
  };


  Users = () => {
    const { users, loading } = this.state;

    if (Object.keys(users).length && users.length) {
      return users.length ? (
      users.map((user, index) => {
        return (
          <>
           <tr key={index}>
             <th scope="row">
               <Media className="align-items-center">
                 <a
                  className="avatar rounded-circle mr-3"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <img
                    alt="..."
                    src={`http://localhost:8888/profiles/${user.avatar}`}
                  />
                </a>
                <Media>
                  <span className="mb-0 text-sm">
                    {user.name} {user.last_name}
                  </span>
                </Media>
              </Media>
            </th>
            <td>{this.getStatusUser(user)}</td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{cpfMask(user.cpf)}</span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{user.email}</span>
              </div>
            </td>
            <td className="text-right">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  href="#pablo"
                  role="button"
                  size="sm"
                  color=""
                  onClick={e => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                    Verificar Transações
                  </DropdownItem>
                  <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                    Pagar conta
                  </DropdownItem>
                  <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                    Banir Usuario
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          </tr>
          </>
        )
        })
      
      ) : (
      <tr>
        <th scope="row">
          <span>
          {loading &&
              <h3>
                 <img src={logo} width="50px" alt="loading..." />
                   <span className="m-4">
                      Carregando registros...
                   </span>
              </h3>
          }
          </span>
        </th>
      </tr>
      );
    }
  };

  render() {
    const { query } = this.state;

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <div className="m-3">
                    <h3 className="m-2">Usúarios</h3>
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                      size="md"
                    >
                      Ativos
                    </Button>
                    <Button
                      color="warning"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                      size="md"
                    >
                      Inativos
                    </Button>
                  </div>
                    <Container>
                    <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      <FontAwesomeIcon icon={faSearch} size="lg" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Pesquisar usuarios"
                      id="query-search"
                      name={query}
                      value={query}
                      onChange={this.handleOnInputChange}
                    />
                    </InputGroup>
                    </Container>


                </CardHeader>
                <Table className="align-items-center table-hover" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Nome</th>
                      <th scope="col">CPF</th>
                      <th scope="col">E-mail</th>
                      <th scope="col" />
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>{this.Users()}</tbody>
                </Table>
              </Card>
            </div>
          </Row>
          {this.Paginate()}
        </Container>
      </>
    );
  }
}

export default TableUser;
