import React from "react";
import api from "../../services/api";
import logo from "../../assets/img/theme/loading.gif";
import { Link } from "react-router-dom";
import ReactCopyButtonWrapper from "react-copy-button-wrapper";

import { cpfMask } from "../../helpers/maskCpf";
import ModalEditPayment from "./modal";
import Header from "../components/Headers/Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import {
  Badge,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  PaginationItem,
  PaginationLink,
  Pagination
} from "reactstrap";

class TablePayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      payments: [],
      loading: false,
      message: '',
      modal: false,
      paginate: null
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  fetchSearchResults = async (query) => {

    const {data} = await api.get(`admin/payments?query=${query}`);

    if(!data){
      this.RequestPayments();
      console.log('não há pagamentos');
    } else {
      this.setState({
        payments: data,
      })    
    }
  }

  componentDidMount() {
    this.RequestPayments();
  }

  Paginate = () => {
    const { paginate } = this.state;

    if (paginate === null) return <> </>;

    const { last_page, current_page } = paginate;

    let pageItem = [];
    let nextPage = current_page === last_page ? 0 : current_page + 1;
    let previousPage = current_page !== 1 ? current_page - 1 : 0;

    for (let i = 0; i < paginate.last_page; i++) {
      let indication = i + 1;

      pageItem.push(
        <PaginationItem
          key={Math.random() * i}
          className={i === current_page - 1 ? "active" : "none"}
        >
          <PaginationLink
            onClick={event => this.RequestPayments(event)}
            value={indication}
          >
            {indication}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return (
      <Pagination className="justify-content-center d-flex mt-3">
        <div className="d-flex">
          <PaginationItem className={previousPage === 0 ? "disabled" : ""}>
            <PaginationLink
              onClick={event => this.RequestPayments(event)}
              tabIndex="-1"
              value={previousPage}
            >
              <i className="fa fa-angle-left" />
              <span className="sr-only">Anterior</span>
            </PaginationLink>
          </PaginationItem>

          {pageItem}

          <PaginationItem className={nextPage === 0 ? "disabled" : ""}>
            <PaginationLink
              onClick={event => this.RequestPayments(event)}
              value={nextPage}
            >
              <i className="fa fa-angle-right" />
              <span className="sr-only"> Proximo </span>
            </PaginationLink>
          </PaginationItem>
        </div>
      </Pagination>
    );
  };


  RequestPayments = async event => {
    const page = typeof event !== "undefined" ? event.target.value : null;


    await api.get('admin/payments', {
      params: {
        page
      }
    }).then(response => {
      this.setState({ 
        payments: response.data.payments.data,
        paginate: response.data.payments,
        total: response.data.payments.total

      });
    }).catch(function (error){
      console.log(error);
    });
  }

  handleOnInputChange = ( event ) => {
    const query = event.target.value;
    if (!query) {
      this.setState({ query, message: '' });
      this.RequestPayments();
		} else {
      this.setState({
        query: query,
        loading: true,
        message: ''
      }, () => {
        this.fetchSearchResults(query);
      });
		}
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  getStatusPayment({ situation }) {
    if (situation.name === "Em analise") {
      return (
        <Badge className="badge-dot mr-4 text-dark">
          <i className="bg-warning" />
        </Badge>
      );
    }

    if (situation.name === "Aprovado") {
      return (
        <Badge className="badge-dot mr-4 text-dark">
          <i className="bg-success" />
        </Badge>
      );
    }

    if (situation.name === "Pendente") {
      return (
        <Badge className="badge-dot mr-4 text-dark">
          <i className="bg-danger" />
        </Badge>
      );
    }

    if (situation.name === "Erro na transação") {
      return (
        <Badge className="badge-dot mr-4 text-dark">
          <i className="bg-primary" />
        </Badge>
      );
    }

    if (situation.name === "Erro na transação") {
      return (
        <Badge className="badge-dot mr-4 text-dark">
          <i className="bg-warning" />
        </Badge>
      );
    }
  }

  handleClick(payments) {
    this.setState({ payments });
  }

  Payments = () => {
    const { payments, loading } = this.state;

    if (Object.keys(payments).length && payments.length) {
      return payments.length ? (
      payments.map((payment, index) => {
        return (
          <>
          <tr key={payment}>
            <th scope="row">
            
                <Media className="blue">
                  <Link to={`/admin/pagamentos/${payment.id}`}>
                    <span className="mb-0 text-sm">{payment.name_bill}</span>
                  </Link>
                </Media>
            </th>
            <td>
              <Media>
                <span className="mb-0">
                  <h2>R$ {payment.amount}</h2>
                </span>
              </Media>
            </td>
            <td>
              <p>
              {payment.situation.name} {this.getStatusPayment(payment)}
              </p>
            </td>
            <td>
              <div className="align-items-center">
                <span className="mr-2">
                  {/* this.state.payments.data.length */}
                  <h3>{payment.date_formatted}</h3>
                </span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">
                  <h3>
                    {payment.usuario.name} {payment.usuario.last_name}
                    <p>{cpfMask(payment.usuario.cpf) || "Sem campo cadastrado"}</p>
                  </h3>
                </span>
              </div>
            </td>
            <td className="text-right">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  href="#pablo"
                  role="button"
                  size="sm"
                  color=""
                  onClick={e => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <Link to={`/admin/pagamentos/${payment.id}`}>
                    <DropdownItem
                      color="primary"
                      className="text-center mt-1 mb-0"
                    >
                      Visualizar
                    </DropdownItem>
                  </Link>

                  <DropdownItem divider />

                  <DropdownItem
                    onClick={() =>
                      this.setState({
                        modal: true,
                        paymentId: payment.id,
                        currentSituationPay: payment.situation,
                        paymentName: payment.name_bill,
                        paymentUsername: payment.usuario.full_name
                      })
                    }
                  >
                    Alterar status do Pagamento
                  </DropdownItem>
                  <DropdownItem divider />
                  <ReactCopyButtonWrapper text={payment.our_number}>
                    <DropdownItem
                      color="primary"
                      className="text-center mt-1 mb-0"
                      id={`tooltip${payment.id}`}
                    >
                      Copiar Código <i className="fas fa-copy" />
                      <UncontrolledTooltip
                        delay={0}
                        placement="bottom"
                        target={`tooltip${payment.id}`}
                      >
                        {payment.our_number}
                      </UncontrolledTooltip>
                    </DropdownItem>
                  </ReactCopyButtonWrapper>
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          </tr>
          </>
        )
        })
      
      ) : (
      <tr>
        <th scope="row">
          <span>
          {loading &&
              <h3>
                 <img src={logo} width="50px" alt="loading..." />
                   <span className="m-4">
                      Carregando registros...
                   </span>
              </h3>
          }
          </span>
        </th>
      </tr>
      );
    }
  }

  render() {
    const { query, total, message } = this.state;

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <div className="m-1">
                    <h3 className="m-2">Pagamentos</h3>
                    <h2 className="m-2 mb-3">
                      <Badge color="primary">
                        <strong>{total || ''}</strong> cadastrados
                      </Badge>
                    </h2>
                    <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FontAwesomeIcon icon={faSearch} size="lg" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Pesquisar pagamentos"
                      id="query-search"
                      name={query}
                      value={query}
                      onChange={this.handleOnInputChange}
                    />
                    </InputGroup>
                  </div>
                </CardHeader>
                <Table className="align-items-center table-hover" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Nome</th>
                      <th scope="col">Valor do Título</th>
                      <th scope="col">Status</th>
                      <th scope="col">Data de Vencimento</th>
                      <th scope="col">Usuário</th>
                      <th scope="col">Ações</th>
                     
                    </tr>
                  </thead>
                  {message === true ? (
                  <div>
                    <p>{message}</p>
                  </div>
                  ) :(
                    <></>
                  )
                  }
                  <tbody>{this.Payments()}</tbody>
                </Table>
              </Card>
              <ModalEditPayment
                size="lg"
                toggle={this.toggle}
                isOpen={this.state.modal}
                modalTransition={{ timeout: 200 }}
                backdropTransition={{ timeout: 300 }}
                paymentId={this.state.paymentId}
                currentSituationPay={this.state.currentSituationPay}
                paymentName={this.state.paymentName}
                paymentUsername={this.state.paymentUsername}
                setPayments={this.handleClick.bind(this)}
              ></ModalEditPayment>
            </div>
          </Row>
          {this.Paginate()}
        </Container>
      </>
    );
  }
}

export default TablePayment;
