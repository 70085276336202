import { call, put } from "redux-saga/effects";
import api from "../../services/api";
import { Creators as AuthCreators, Types } from "../ducks/auth";
import { push } from "connected-react-router";

export function* authorize({ payload: { email, password } }) {
  const user = {email, password}
  try {
    const { data } = yield call(api.post, "auth/admin/login", user);

    yield put(AuthCreators.authSuccess(data.user));

    localStorage.setItem("@nubiz:token", data.token);
    // localStorage.setItem("@nubiz:user", data.user);

    yield put(push("/admin/home"));

  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Erro interno servidor";
        break;
      case 401:
        message = "Credenciais Invalidas";
        break;
      default:
        message = "Algo deu errado";
    }

    yield put({ type: Types.AUTH_ERROR, payload: message });
    localStorage.removeItem("@nubiz:token");
  }
}
