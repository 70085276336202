import axios from "axios";
import { getToken } from "./auth";

const api = axios.create({
  baseURL: 'https://nubiz-api.soterotech.net/api/',
  crossDomain: true,
  "Access-Control-Allow-Origin": 'https://nubiz.soterotech.com.br',
  "Content-Type": "application/json"
});

api.interceptors.request.use(config => {
  const token = getToken();
  if (token) {
    const token = getToken();
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
