import { call, put } from "redux-saga/effects";
import api from "../../services/api";
import { Creators as RegisterCreators, Types } from "../ducks/register";
import { push } from "connected-react-router";

export function* register({
  payload: { name, last_name, email, password, cpf, estado, cidade }
}) {
  try {
    const response = yield call(api.post, "auth/register", {
      name,
      last_name,
      email,
      password,
      cpf,
      estado,
      cidade
    });

    const { data } = response;

    yield put(RegisterCreators.registerResponse(data));

    localStorage.setItem("@nubiz:token", data.token);

    yield put(RegisterCreators.registerData(data));

    yield put(push("/admin/home"));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = "Something went wrong";
    }
    yield put({ type: Types.REGISTER_ERROR, payload: message });
    localStorage.removeItem("token");
  }
}
