import React from "react";
import api from "../../services/api";
// import PropTypes from "prop-types";
import Select from "react-select";
// import logo from "../../assets/img/theme/loading.gif";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Form,
  ButtonGroup
} from "reactstrap";

class ModalEditPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentId: "",
      paymentName: "",
      paymentUsername: "",
      msgModal: "",
      currentSituationPay: "",
      situation: {},
      situationId: '',
      nameSituation: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    // if(this.props.currentSituationPay){
    //   await this.setState({nameSituation: this.props.currentSituationPay})
    // } else {
      await api
      .get('admin/status')
      .then(response => {
        this.setState({
          nameSituation: response.data.status,
        })
      })
      .catch(err => {
        this.setState({ msgModal: "Houve um erro, tente novamente!" });
      });
    // }
  }


  createOptions = () => {
    const { nameSituation } = this.state

    const options = nameSituation.map(item => {
      return {label: item.name, value: item.id};
    });

    return options;
  };


  handleClose = () => {
    this.setState({ isOpen: false });
  };

  notifySuccess = (payment, payer) => toast(`${payment.toUpperCase()} DE ${payer.toUpperCase()} FOI ATUALIZADO`,
  { type: toast.TYPE.SUCCESS, autoClose: 5000 , closeButton: true });
  notifyDanger = (payment) => toast(`HOUVE ERRO EM ${payment.toUpperCase()}, VERIFIQUE E TENTE NOVAMENTE`, 
  { type: toast.TYPE.DANGER, autoClose: 5000, closeButton: true });


  handleChange(item) {
    this.setState({situation: item});
  }

  handleSubmit = async event => {
    const { paymentId, paymentName, paymentUsername } = this.props;
    const { situation } = this.state;

    event.preventDefault();

    await this.props.toggle();

    this.props.setPayments([]);
    try {
      const { data: { data } } = await api.put(`/admin/payments/${paymentId}/${situation.value}`, {
        situation_pay_id: situation.value
      });

      this.props.setPayments(data);

      this.notifySuccess(paymentName, paymentUsername);

    } catch (error) {
      this.notifyDanger(paymentName);
    }

  };

  currentSituation = () => {
    if(this.props.currentSituationPay !== undefined) {
      return (
          <>
              {this.props.currentSituationPay.name}
          </>
      );
  } else {
    return (
      <>Carregando...</>
    );
  }
  }

  render() {
    const { paymentUsername, paymentName, className } = this.props;
    return (
      <>
      <ToastContainer />
      <Modal
        toggle={this.toggle}
        {...this.props}
        size="lg"
        className={className}
        centered
      >
        <ModalHeader toggle={this.toggle} charCode="X">
          ALTERAR STATUS DO PAGAMENTO
          <br />
          <h1>{paymentName}</h1>
          <br />
          <p className="h2">Pagador: {paymentUsername} | Situação atual: {this.currentSituation()}</p>
        </ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label for="exampleSelect">
              <h2 color="primary">Selecione a opção correspondente</h2>
            </Label>
            <Form>
              <Select
                onChange={item => this.handleChange(item)}
                options={this.createOptions()}
                name="filters"
                placeholder="Selecionar status"
                value={this.state.situation}
              />
            </Form>
            <p className="mt-2">
              <b>{this.state.msgModal}</b>
            </p>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="lg">
            <Button
              color="primary"
              type="submit"
              onClick={e => this.handleSubmit(e)}
            >
              Salvar
            </Button>
            <Button onClick={this.props.toggle}>Cancelar</Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
      </>
    );
  }
}

export default ModalEditPayment;
