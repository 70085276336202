import React from "react";

import { Button, Container, Row, Col } from "reactstrap";

class UserHeader extends React.Component {
  render() {
    return (
      <>
        <div
          className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
          style={{
            minHeight: "600px",
            backgroundImage:
              "url('http://localhost:8888/profiles/user.png')",
            backgroundSize: "cover",
            backgroundPosition: "center top"
          }}>
          {/* Mask */}
          <span className="mask bg-gradient-default opacity-8" />
          {/* Header container */}
          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="7" md="10">
                <h1 className="display-2 text-white">Olá</h1>
                <p className="text-white mt-0 mb-5">
                  Esta é a sua página de perfil. Você pode ver o progresso que
                  você fez com o seu trabalho e gerenciar seus projetos ou
                  tarefas atribuídas
                </p>
                <Button
                  color="info"
                  // href="#pablo"
                  size="lg"
                  // onClick={e => e.preventDefault()}
                >
                  Editar Perfil
                </Button>
                {/* <Button
                  color="primary"
                  href="#pablo"
                  size="lg"
                  onClick={e => e.preventDefault()}
                >
                  Metodos de Pagamento
                </Button> */}
                {/* <Button
                  color="success"
                  href="#pablo"
                  size="lg"
                  onClick={e => e.preventDefault()}
                >
                  Suporte por chat
                </Button> */}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default UserHeader;
