import React from "react";
import { Link } from "react-router-dom";
// import { logout } from "../../services/auth";
import { Creators } from "../../../store/ducks/logout";
import {connect} from 'react-redux';

// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  // Form,
  // FormGroup,
  // InputGroupAddon,
  // InputGroupText,
  // Input,
  Button,
  // InputGroup,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

class AdminNavbar extends React.Component {

  handleSignOut = () => {
    const {logout} = this.props;
    logout(localStorage.getItem("@nubiz:token"));
  };

  render() {
    const { user } = this.props;

    return (
      <>
        <Navbar
          className="navbar-top navbar-dark col-md-12 col-lg-12 ml-auto bg-gradient-info"
          expand="md"
          id="navbar-main"
          style={{position: 'initial'}}
        >
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              <h1 className="text-white">{this.props.brandText}</h1>
            </Link>
            {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Buscar" type="text" />
                </InputGroup>
              </FormGroup>
            </Form> */}
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                  <Media className="ml-2 mr-3">
                      <span className="mb-0 text-sm  font-weight-bold">
                        <h2 className="text-white">{user.name} {user.last_name}</h2>
                      </span>
                    </Media>
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={`http://localhost:8888/profiles/user.png`}
                      />
                    </span>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Bem vindo!</h6>
                  </DropdownItem>
                  <DropdownItem to={'/admin/profile/'} tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>Meu perfil</span>
                  </DropdownItem>
                  <DropdownItem to={'/admin/profile/'} tag={Link}>
                    <i className="ni ni-support-16" />
                    <span>Suporte</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <div className="justify-content-center text-center">
                    <Button color="danger" size="xl" onClick={this.handleSignOut}>
                      Sair <i className="ni ni-user-run 2x" />
                    </Button>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = state => ({
  ...state,
  user: state.auth.data,
});

const mapDispatchToProps = dispatch => {
  return {
    logout: (token) =>
      dispatch(Creators.logout(token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminNavbar);

