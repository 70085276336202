import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  NavbarBrand,
  Navbar,
  Container,
} from "reactstrap";

const logo = require('../../../assets/img/brand/logo.png');
class AdminNavbar extends React.Component {
  render() {
    return (
      <>
        <Navbar
          className="navbar-top navbar-horizontal navbar-dark"
          expand="md"
        >
          <Container className="px-4 justify-content-center">
            <NavbarBrand to="/" tag={Link}>
              <img src={logo} alt="logo"/>
              {/* <h1 className="text-white">Nubiz Pagamentos</h1> */}
            </NavbarBrand>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
