import Index from "./views/Index";
import Profile from "./views/pages/Profile";
import TableUser from "./views/pages/tableListUser";
import TablePayment from "./views/pages/tableListPayments";
import TableEmployee from "./views/pages/tableListEmployee";

var Routes = [
  {
    path: "/home",
    name: "Pagina Inicial",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/pagamentos",
    name: "Pagamentos",
    icon: "ni ni-credit-card text-info",
    component: TablePayment,
    layout: "/admin"
  },
  {
    path: "/usuarios",
    name: "Usuários",
    icon: "ni ni-single-02 text-red",
    component: TableUser,
    layout: "/admin"
  },
  {
    path: "/funcionarios",
    name: "Funcionários",
    icon: "ni ni-briefcase-24 text-blue",
    component: TableEmployee,
    layout: "/admin"
  },
  {
    path: "/profile",
    name: "Perfil",
    icon: "ni ni-circle-08 text-yellow",
    component: Profile,
    layout: "/admin"
  }
];

export default Routes;
